import React, { useState, FC, ReactElement } from 'react';
import type { LensTheme } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/types';
import { Button, Drawer, useTheme } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import SwitchComponent from '../SwitchComponent/SwitchComponent';
import FavoriteSortableApps from '../FavoriteSortableApps';
import { useTranslation } from 'react-i18next';
import AppConfig from '../../appConfig';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import FavoriteApps from '../FavoriteApps/FavoriteApps';
import { FavoriteAppData } from '../../../types';
import { HeaderDivider, FooterButtonContainer } from './FavoriteAppSettings.style';
import favoriteAppStore from '../../helpers/favoriteGlobalStore/FavoriteAppStore';
import useScreenSize from '../../hooks/useScreenSize';
interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  appKey?: string;
  data: CombinedData;
  onSaveFavoriteApps: () => void;
}

const FavoriteSection: FC<PopupDialogProps> = ({ open, onClose, title, data, onSaveFavoriteApps }): ReactElement => {
  const theme = useTheme<LensTheme>();
  const [sortableApps, setsortableApps] = useState<FavoriteAppData[]>([]);
  const SideSheetPresets = theme.presets.SideSheetPersets;
  const { components } = SideSheetPresets;
  const { Header, Footer, Content } = components;
  const { t } = useTranslation();
  const screenSize = useScreenSize();

  const handleSortableApps = (sortApps: FavoriteAppData[]) => {
    setsortableApps(sortApps);
  };

  const handleFavoriteButtonClicked = (appData: FavoriteAppData) => {
    //check if its already present in  sortable apps, if yes remove it
    const app = sortableApps.find((app: FavoriteAppData) => app.appKey === appData.appKey);
    if (app) {
      const newApps = sortableApps.filter((app: FavoriteAppData) => app.appKey !== appData.appKey);
      handleSortableApps(newApps);
    } else {
      handleSortableApps([...sortableApps, appData]);
    }
  };

  const saveFavoriteApps = () => {
    // Implement the save functionality here

    favoriteAppStore.setFavoriteApps(sortableApps);
    const favoriteApps = favoriteAppStore.getFavoritesApps();
    localStorage.setItem('favoriteApps', JSON.stringify(favoriteApps));
    //implement error handling after the API implementaion
    onSaveFavoriteApps();
    onClose();
  };

  const renderSideSheetContent = (
    <>
      <Header
        onClose={onClose}
        titleText={title}
        closeButtonProps={{ 'aria-label': 'Close side sheet', 'aria-controls': 'sideSheetId' }}
      />
      <Content>
        {renderFavoriteTabs()}
        {/* future implementaion not in scope */}
        {AppConfig.featureToggles.showFavoriteSwitch && (
          <>
            <SwitchComponent></SwitchComponent>
            <HeaderDivider></HeaderDivider>
          </>
        )}

        <FavoriteSortableApps
          sortableApps={sortableApps}
          handleSortableApps={handleSortableApps}
          handleFavoriteButtonClicked={handleFavoriteButtonClicked}
        ></FavoriteSortableApps>
        <FavoriteApps
          data={data}
          sortableApps={sortableApps}
          handleFavoriteButtonClicked={handleFavoriteButtonClicked}
        ></FavoriteApps>

        {/* {renderFavoriteContent(appKey)} */}
      </Content>
      <Footer area-id="hello-id">
        <FooterButtonContainer>
          <Button variant="text" onClick={onClose} size="medium">
            {t('favoriteApps.sidebar_close_button_label')}
          </Button>
          <Button onClick={saveFavoriteApps} variant="contained" color="primary" size="medium">
            {t('favoriteApps.sidebar_done_button_label')}
          </Button>
        </FooterButtonContainer>
      </Footer>
    </>
  );
  function getPopUpWidth() {
    const isMobile = screenSize < AppConfig.constants.mobileBreakpoint;
    return isMobile ? '100%' : '430px';
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: getPopUpWidth() },
      }}
    >
      {renderSideSheetContent}
    </Drawer>
  );
};

export default FavoriteSection;

function renderFavoriteTabs() {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(0);
  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: any) => {
    setSelectedValue(newValue);
  };
  return (
    <Tabs onChange={handleChange} value={selectedValue} aria-label="favorite-tabs">
      <Tab label={t('favoriteApps.tab_item_favorite')} key="tab-favorite" />
      <Tab label={t('favoriteApps.tab_item_section_rder')} key="tab-SectionOrder" />
    </Tabs>
  );
}
