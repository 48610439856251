import React, { ReactElement } from 'react';
import { FavoriteAppData, FavoriteCardComponentType } from '../../../types';
import FavoriteCard from '../FavoriteCard';
import Sortable from '../DragAndDropSorter';
import '@diligentcorp/atlas-web-component-icons/lib/star/index.js';
import {
  FavoriteSecHeadingContainer,
  FavoriteSecHeading,
  FavoriteStarIcon,
  FavoriteSecHeadingDetails,
  FavoriteSubHeading,
} from './FavoriteSortableApps.style';
import { useTranslation } from 'react-i18next';
import FavoriteEmptySection from '../FavoriteEmptySection';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dil-star-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement> & { variant?: string }, HTMLElement>;
    }
  }
}
function reorder(sortableApps: FavoriteAppData[], source: number, destination: number) {
  const newApps = [...sortableApps];
  const [removed] = newApps.splice(source, 1);
  newApps.splice(destination, 0, removed);
  return newApps;
}

interface FavoriteSortableAppsProps {
  sortableApps: FavoriteAppData[];
  handleSortableApps: (sortApps: FavoriteAppData[]) => void;
  handleFavoriteButtonClicked: (appData: FavoriteAppData) => void;
}
const FavoriteSortableApps = ({
  sortableApps,
  handleSortableApps,
  handleFavoriteButtonClicked,
}: FavoriteSortableAppsProps): ReactElement => {
  const { t } = useTranslation();
  const isSortableAppsExist = sortableApps.length > 0;
  function sortChangeHandler(source: number, destination: number | null): void {
    if (destination !== null) {
      const newApps = reorder(sortableApps, source, destination);
      handleSortableApps(newApps);
    }
  }

  return (
    <>
      <FavoriteSecHeadingContainer>
        <FavoriteSecHeadingDetails>
          {isSortableAppsExist && (
            <FavoriteStarIcon aria-label="Favorite" size="large">
              <dil-star-icon></dil-star-icon>
            </FavoriteStarIcon>
          )}
          <FavoriteSecHeading variant="h4">{t('favoriteApps.sidebar_title')}</FavoriteSecHeading>
        </FavoriteSecHeadingDetails>
        {!isSortableAppsExist && (
          <FavoriteSubHeading> {t('favoriteApps.sidebar_empty_section_label')}</FavoriteSubHeading>
        )}
      </FavoriteSecHeadingContainer>

      {isSortableAppsExist ? (
        <Sortable onChange={sortChangeHandler}> {renderDraggableItems()}</Sortable>
      ) : (
        <FavoriteEmptySection cardCount={1}></FavoriteEmptySection>
      )}
    </>
  );

  function renderDraggableItems() {
    return sortableApps.map((app, index) => {
      return (
        <Sortable.sortableElement key={index} sortID={index}>
          <FavoriteCard
            key={app.appKey}
            favoriteAppData={app}
            onFavoriteButtonClick={(value: FavoriteAppData) => handleFavoriteButtonClicked(value)}
            componentType={FavoriteCardComponentType.Draggable}
            isFavorite={true} // always true as this element is for favorites section
          />
        </Sortable.sortableElement>
      );
    });
  }
};

export default FavoriteSortableApps;
